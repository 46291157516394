// ex: { a: 1, b: 2 } => a=1&b=2

export function objectToQueryString(obj = {}) {
  return objectToQueryComponents(obj).join('&')
}

// ex: a=1&b=2 => { a: 1, b: 2 }

export function getQueryObjectFromUrl(url) {
  const currentUrl = new URL(url)
  const search = currentUrl.search

  if (search === '') return {}

  return queryStringToObject(search)
}

function objectToQueryComponents(obj, parentKey = '') {
  let components = []

  for (const [key, value] of Object.entries(obj)) {
    const encodedKey = parentKey
      ? `${parentKey}[${encodeURIComponent(key)}]`
      : encodeURIComponent(key)

    if (typeof value === 'object' && value !== null) {
      if (Array.isArray(value)) {
        // For arrays, append '[]' to the key
        value.forEach((val, i) => {
          if (typeof val === 'object') {
            components.push(...objectToQueryComponents(val, `${encodedKey}[]`))
          } else {
            components.push(`${encodedKey}[]=${encodeURIComponent(val)}`)
          }
        })
      } else {
        // For nested objects, recurse without '[]'
        components.push(...objectToQueryComponents(value, encodedKey))
      }
    } else {
      if (!value) continue

      components.push(`${encodedKey}=${encodeURIComponent(value)}`)
    }
  }

  return components
}

function queryStringToObject(queryString) {
  const params = new URLSearchParams(queryString)
  const result = {}

  for (const [key, value] of params.entries()) {
    const keys = key.split(/\[|\]/).filter((k) => k) // Split keys and filter out empty strings
    keys.reduce((acc, k, i) => {
      if (i === keys.length - 1) {
        acc[k] = value
      } else {
        acc[k] = acc[k] || {}
      }
      return acc[k]
    }, result)
  }

  return result
}
